import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const AgeApi = createApi({
    reducerPath: 'AgeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }    }),
    tagTypes: ['age'],
    endpoints: (builder) => ({
        createAge: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.AGE_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['age']
        }),
      
        getAgeList:builder.query<any, void>({
            query: () => ({
                url: urlConstants.AGE_LIST,
                method: 'GET',
            }),
            providesTags: ['age']
        }), 
        editAge: builder.mutation<any, any>({
            query: ({body,id}:any) => ({
                url: `${urlConstants.AGE}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['age']
        }),
        getSingleAge: builder.query<any,any>({
            query: (id:any) => ({
                url:  `${urlConstants.AGE}/${id}`,
                method: 'GET',
            }),
            providesTags: ['age']
        }), 
         deleteAge: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.AGE}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['age']
        }),
      
    })

})
export const {
    useCreateAgeMutation,
    useGetSingleAgeQuery,
    useGetAgeListQuery,
    useEditAgeMutation,
    useDeleteAgeMutation
} = AgeApi