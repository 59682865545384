import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const DashboardApi = createApi({
    reducerPath: 'DashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['dashboard'],
    endpoints: (builder) => ({
        getDashboardDetails: builder.query<any, void>({
            query: () => ({
                url: urlConstants.DASHBOARD,
                method: 'GET',
            }),
            providesTags: ['dashboard']
        }),
    })

})
export const { useGetDashboardDetailsQuery } = DashboardApi