import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const FieldEmployeesApi = createApi({
    reducerPath: 'FieldEmployeesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['fieldEmployeesApi','salesForce'],
    endpoints: (builder) => ({
        createFieldEmployee: builder.mutation<any, any>({
            query: (userData: any) => ({
                url: urlConstants.FIELD_EMPLOYEE_CREATE,
                method: 'POST',
                body: userData

            }), invalidatesTags: ['fieldEmployeesApi']
        }),

        getFieldEmployeesList: builder.query<any, any>({
            query: (searchPrams:any) => ({
                url: urlConstants.FIELD_EMPLOYEE_LIST,
                method: 'GET',
                params:{
                    page:searchPrams?.currentPage,
                    // limit:1,
                    searchString:searchPrams?.searchString
                }
            }),
            providesTags: ['fieldEmployeesApi']
        }),
        editFieldEmployee: builder.mutation<any, any>({
            query: ({ userData, id }: any) => ({
                url: `${urlConstants.FIELD_EMPLOYEE}/${id}`,
                method: 'PUT',
                body: userData

            }), invalidatesTags: ['fieldEmployeesApi']
        }),
        getSingleFieldEmployee: builder.query<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.USER}/${id}`,
                method: 'GET',
            }),
            providesTags: ['fieldEmployeesApi']
        }),
        deleteFieldEmployee: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.USER}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['fieldEmployeesApi']
        })
    })
})

export const {
    useCreateFieldEmployeeMutation,
    useGetFieldEmployeesListQuery,
    useGetSingleFieldEmployeeQuery,
    useEditFieldEmployeeMutation,
    useDeleteFieldEmployeeMutation,
} = FieldEmployeesApi