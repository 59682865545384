import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const RoleApi = createApi({
    reducerPath: 'RoleApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['role'],
    endpoints: (builder) => ({
        createRole: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.ROLE_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['role']
        }),

        getRoleList: builder.query<any, any>({
            query: (searchPrams:any) => ({
                url: urlConstants.ROLE_LIST,
                method: 'GET',
                params:{
                    page:searchPrams?.currentPage,
                    // limit:1,
                    searchString:searchPrams?.searchString,
                }
            }),
            providesTags: ['role']
        }),
        editRole: builder.mutation<any, any>({
            query: ({ body, id }: any) => ({
                url: `${urlConstants.ROLE}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['role']
        }),
        getSingleRole: builder.query<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.ROLE}/${id}`,
                method: 'GET',
            }),
            providesTags: ['role']
        }),
        deleteRole: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.ROLE}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['role']
        }),
        getRolePermissions: builder.query<any, void>({
            query: () => ({
                url: urlConstants.USER_PERMISSSION,
                method: 'GET',
            }),
            providesTags: ['role']
        }),

    })

})
export const {
    useCreateRoleMutation,
    useGetSingleRoleQuery,
    useGetRoleListQuery,
    useEditRoleMutation,
    useDeleteRoleMutation,
    useGetRolePermissionsQuery
} = RoleApi