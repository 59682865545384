import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const DepartmentApi = createApi({
    reducerPath: 'DepartmentApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`)
            return headers
        }
    }),
    tagTypes: ['department'],
    endpoints: (builder) => ({
        createDepartment: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.DEPARTMENT_CREATE,
                method: 'POST',
                body: body

            }), invalidatesTags: ['department']
        }),

        getDepartmentList: builder.query<any, void>({
            query: () => ({
                url: urlConstants.DEPARTMENT_LIST,
                method: 'GET',
            }),
            providesTags: ['department']
        }),
        editDepartment: builder.mutation<any, any>({
            query: ({ body, id }: any) => ({
                url: `${urlConstants.DEPARTMENT}/${id}`,
                method: 'PUT',
                body: body

            }), invalidatesTags: ['department']
        }),
        getSingleDepartment: builder.query<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.DEPARTMENT}/${id}`,
                method: 'GET',
            }),
            providesTags: ['department']
        }),
        deleteDepartment: builder.mutation<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.DEPARTMENT}/${id}`,
                method: 'DELETE',

            }), invalidatesTags: ['department']
        }),

    })

})
export const {
    useCreateDepartmentMutation,
    useGetSingleDepartmentQuery,
    useGetDepartmentListQuery,
    useEditDepartmentMutation,
    useDeleteDepartmentMutation
} = DepartmentApi