import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

export const ReportApi = createApi({
    reducerPath: 'ReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`);
            return headers;
        }
    }),
    tagTypes: ['report'],
    endpoints: (builder) => ({
        getReportList: builder.query<any, any>({
            query: (searchPrams: any) => ({
                url: urlConstants.REPORT,
                method: 'GET',
                params: {
                    fromDate: searchPrams.startDate,
                    toDate: searchPrams.endDate,
                    status: searchPrams.status,
                    projectId: searchPrams.project,
                    purpose: searchPrams.responseType,
                    page: searchPrams.page,
                }
            }),
            providesTags: ['report'],
            keepUnusedDataFor: 0,
        }),
        getReviewReportList: builder.query<any, any>({
            query: (searchPrams: any) => ({
                url: urlConstants.REVIEW_REPORT,  
                method: 'GET',
                params: {
                    fromDate: searchPrams.startDate,
                    toDate: searchPrams.endDate,
                    status: searchPrams.status,
                    projectId: searchPrams.project,
                    purpose: searchPrams.responseType,
                    page: searchPrams.page,
                }
            }),
            providesTags: ['report'],
            keepUnusedDataFor: 0,
        })
    })
});

export const {
    useGetReportListQuery,
    useGetReviewReportListQuery
} = ReportApi;
