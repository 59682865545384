import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

export const ReviewModelApi = createApi({
    reducerPath: 'ReviewModelApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cw-token')}`);
            return headers;
        }
    }),
    tagTypes: ['reviewModel', 'reviewRearrange'],
    endpoints: (builder) => ({
        createReviewModel: builder.mutation<any, any>({
            query: (body: any) => ({
                url: urlConstants.REVIEW_MODEL_ADD,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['reviewModel']
        }),
        updateReviewModel: builder.mutation<any, any>({
            query: ({id,formatbodyData}:any) => ({
                url: `${urlConstants.REVIEW_MODEL}/${id}`,
                method: 'PUT',
                body: formatbodyData
            }),
            invalidatesTags: ['reviewModel']
        }),

        getReviewModelList: builder.query<any, void>({
            query: () => ({
                url: urlConstants.REVIEW_MODEL_LIST,
                method: 'GET',
            }),
            providesTags: ['reviewModel']
        }),

        getSpecificReviewModel: builder.query<any,any>({
            query: (id:any) => ({
                url: `${urlConstants.REVIEW_MODEL}/${id}`,
                method: 'GET',
            }),
            providesTags: ['reviewModel']
        }),
        updateReviewOrder: builder.mutation<any, any>({
            query: (body : any) => ({
                url: urlConstants.REVIEW_REARRANGE,
                method: 'POST',
                body: body
            }),
            invalidatesTags: ['reviewRearrange']
        }),
    })
});

export const {
    useCreateReviewModelMutation,
    useGetReviewModelListQuery,
    useGetSpecificReviewModelQuery,
    useUpdateReviewModelMutation,
    useUpdateReviewOrderMutation
} = ReviewModelApi;
